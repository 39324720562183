<template>
  <footer class="footer grid w-full place-content-center bg-black">
    <div class="footer__logo mx-auto">
      <img class="w-full" src="/images/sponsor.webp" alt="logo" />
    </div>
    <small class="footer__copyright block text-center text-white"
      >Despicable Me 4 © Universal City Studios LLC. All Rights
      Reserved.</small
    >
  </footer>
</template>

<style scoped lang="scss">
.footer {
  height: calcMinSp(250);
}

.footer__logo {
  width: calcMinSp(500);
  margin-bottom: calcMinSp(40);
}

.footer__copyright {
  font-size: calcMinSp(18);
}
</style>
